// @flow
import * as questions from './questions';
import type { RawQuizType } from '../../utils/quiz';

export const rope: RawQuizType = {
  id: 'rope-quiz',
  name: 'Rope',
  randomizeQuestionOrder: true,
  maxQuestionCount: 5,
  questions: questions.rope,
};

export const canyonRating: RawQuizType = {
  id: 'canyon-rating-quiz',
  name: 'Canyon Rating',
  randomizeQuestionOrder: true,
  maxQuestionCount: 5,
  questions: questions.canyonRating,
};
