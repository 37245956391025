// @flow
import React from 'react';
import type { RawQuestionType } from '../../../utils/quiz';
import G from '../../../components/glossary-link';
import GlossaryQuote from '../../../components/glossary-quote';
import Link from '../../../components/link';

export const questions: Array<RawQuestionType> = [
  {
    id: 'rope-000',
    text: 'What is the smallest diameter for a non-emergency canyoneering rope?',
    answers: ['5mm', '6mm', '7mm', '8mm'],
    noneOfTheAbove: true,
    correctAnswer: 3,
    postAnswerRemarks: (
      <p>
        Atwood Gear Grand, BlueWater Canyon Extreme, BlueWater Canyon Pro DS,
        BlueWater Canyon Pro, Imlay Canyon Rope, and Sterling Canyon Lux are all
        8mm ropes. Anything smaller isn't rope, it's dental floss.
      </p>
    ),
  },
  {
    id: 'rope-001',
    text: 'What is the largest diameter for a canyoneering rope?',
    answers: ['7mm', '8mm', '9mm', '10mm'],
    noneOfTheAbove: true,
    correctAnswer: 4,
    postAnswerRemarks: (
      <p>
        The Sterling Canyon Tech is the largest{' '}
        <em>
          <Link
            to="https://en.wikipedia.org/wiki/No_true_Scotsman"
            hideExternalLink
          >
            true
          </Link>
        </em>{' '}
        canyoneering rope at 9.5mm. If you got this one wrong it's my fault
        because this is a somewhat subjective question.
      </p>
    ),
  },
  {
    id: 'rope-002',
    text: 'A dynamic rope...',
    answers: [
      'has no place in canyoneering',
      'is stretchier than a static rope',
      'must have a rated tensile breaking strength',
    ],
    randomizeAnswers: true,
    noneOfTheAbove: true,
    allOfTheAbove: true,
    correctAnswer: 1,
    postAnswerRemarks: (
      <p>
        Dynamic ropes have a place in canyoneering as a <G>safety tether</G>,
        are <em>by definition</em> stretchier than static rope, and have no
        rated tensile breaking strength.
      </p>
    ),
  },
  {
    id: 'rope-003',
    text: 'A fast rope is a misnomer for a...',
    answers: [
      'skinny rope',
      'fat rope',
      'greased rope',
      'dry treated rope',
      'rope that skips 2 meals every first Sunday of the month',
    ],
    randomizeAnswers: true,
    noneOfTheAbove: true,
    allOfTheAbove: true,
    correctAnswer: 0,
    postAnswerRemarks: <GlossaryQuote small>fast rope</GlossaryQuote>,
  },
  {
    id: 'rope-004',
    text: 'A slow rope is a misnomer for a...',
    answers: ['skinny rope', 'fat rope', 'efficient rope', 'inefficient rope'],
    randomizeAnswers: true,
    noneOfTheAbove: true,
    allOfTheAbove: true,
    correctAnswer: 1,
    postAnswerRemarks: <GlossaryQuote small>fat rope</GlossaryQuote>,
  },
  {
    id: 'rope-005',
    text: "If you can pinch a rope such that there is no gap in the created bight, there's a good chance you have...",
    answers: [
      'a core shot',
      'an origami addiction',
      'glazing on your rope',
      'a dynamic rope',
    ],
    randomizeAnswers: true,
    noneOfTheAbove: true,
    correctAnswer: 0,
    postAnswerRemarks: <GlossaryQuote small>core shot</GlossaryQuote>,
  },
  {
    id: 'rope-006',
    text: 'All canyoneering-specific rope is static',
    answers: [],
    correctAnswer: 0,
    postAnswerRemarks: <GlossaryQuote small>static rope</GlossaryQuote>,
  },
  {
    id: 'rope-007',
    text: 'All canyoneering rope is kernmantle',
    answers: [],
    correctAnswer: 0,
    postAnswerRemarks: <GlossaryQuote small>kernmantle rope</GlossaryQuote>,
  },
  {
    id: 'rope-008',
    text: 'Elongation is a measure of...',
    answers: [
      'stretch percentage per weight',
      'stretch distance per weight',
      'breaking strength',
      'tensile strength',
    ],
    randomizeAnswers: true,
    noneOfTheAbove: true,
    correctAnswer: 0,
    postAnswerRemarks: <GlossaryQuote small>elongation</GlossaryQuote>,
  },
  {
    id: 'rope-009',
    text: 'Which rope material absorbs less water?',
    answers: ['Nylon', 'Polyester'],
    randomizeAnswers: true,
    correctAnswer: 1,
    postAnswerRemarks: (
      <p>
        <G>Nylon</G> holds significantly more water than any other rope
        material.
      </p>
    ),
  },
  {
    id: 'rope-010',
    text: 'Which is more cut resistant?',
    answers: ['Loosely woven polyester', 'Tightly woven polyester'],
    randomizeAnswers: true,
    correctAnswer: 1,
    postAnswerRemarks: (
      <p>
        The advantages of a tightly woven <G>Polyester</G> <G>sheath</G> rope
        are that it makes the rope more hydrophobic and more durable. The
        advantages of a loosely woven Polyester sheath rope is that it's
        "supple" as you expect a rope to be. The disadvantage is that it is
        extremely weak.
      </p>
    ),
  },
  {
    id: 'rope-011',
    text: 'In general, which elongation is better for rappelling?',
    answers: ['Dynamic elongation is better', 'Static elongation is better'],
    randomizeAnswers: true,
    correctAnswer: 1,
    postAnswerRemarks: (
      <>
        <GlossaryQuote small>static rope</GlossaryQuote>
        <GlossaryQuote small>dynamic rope</GlossaryQuote>
      </>
    ),
  },
  {
    id: 'rope-012',
    text: 'Which rope has the better elongation for rappelling',
    answers: ['Imlay Canyon Fire', 'Sterling C-IV'],
    randomizeAnswers: true,
    correctAnswer: 0,
    postAnswerRemarks: (
      <p>
        Imlay Canyon Fire has a <G>polyester</G> core which offers little
        stretch. Sterling C-IV has a <G>polypropylene</G> core which is very
        stretchy.
      </p>
    ),
  },
  {
    id: 'rope-013',
    text: 'Which rope has the best elongation for rappelling',
    answers: ['BlueWater Canyon Extreme', 'Sterling Canyon Tech'],
    randomizeAnswers: true,
    correctAnswer: 0,
    postAnswerRemarks: (
      <p>
        BlueWater Canyon Extreme has a <G>Dyneema</G> <G>core</G> which offers
        little stretch. Sterling Canyon Tech has a <G>nylon</G> core which is
        very stretchy.
      </p>
    ),
  },
  {
    id: 'rope-014',
    text: 'Which of these is not a canyoneering rope sheath material?',
    answers: ['Polyester', 'Dyneema', 'Technora'],
    randomizeAnswers: true,
    noneOfTheAbove: true,
    correctAnswer: 1,
    postAnswerRemarks: (
      <p>
        <G>Dyneema</G> is only used as a <G>core</G> material. <G>Polyester</G>{' '}
        and <G>Technora</G> are both used as a <G>sheath</G> material.
      </p>
    ),
  },
  {
    id: 'rope-015',
    text: 'Which of these is not a canyoneering rope core material?',
    answers: ['Polyester', 'Nylon', 'Dyneema', 'Polypropylene'],
    randomizeAnswers: true,
    noneOfTheAbove: true,
    correctAnswer: 4,
    postAnswerRemarks: (
      <p>
        <G>Polyester</G>, <G>Nylon</G>, <G>Dyneema</G>, and <G>Polypropylene</G>{' '}
        are all canyoneering rope <G>core</G> material.
      </p>
    ),
  },
  {
    id: 'rope-016',
    text: 'When should you retire a canyoneering rope?',
    answers: [
      'After 3 or so core shots',
      "When you don't trust it anymore",
      'When it no longer looks new',
    ],
    randomizeAnswers: true,
    allOfTheAbove: true,
    noneOfTheAbove: true,
    correctAnswer: 1,
    postAnswerRemarks: (
      <p>
        If it doesn't look new anymore that's okay. The number of{' '}
        <G>core shots</G> doesn't matter though the rope should be cut at the
        core shot and split into two ropes. If you feel uncomfortable for any
        reason or suspect there may be a problem with the rope it should be
        retired.
      </p>
    ),
  },
  {
    id: 'rope-017',
    text: 'When is it appropriate to cut a canyoneering rope?',
    answers: [
      'When there is a core shot.',
      'When it gets fuzzy',
      "When you have to lower someone who's rigged double strand",
    ],
    randomizeAnswers: true,
    allOfTheAbove: true,
    noneOfTheAbove: true,
    correctAnswer: 0,
    postAnswerRemarks: (
      <p>
        Obviously no reason to cut it if it gets fuzzy. For lowering someone on{' '}
        <G>DRT</G>, it's possible that you will have to perform a cut-and-lower,
        but not always required. If you have a core shot and still want to use
        the rope, you'll need to cut it.
        <br />
        One other time I can think when you would want to cut your rope is if
        you <em>want</em> two shorter ropes. You know, you buy a spool and make
        yourself a 300 footer and a few other lengths.
      </p>
    ),
  },
  {
    id: 'rope-018',
    text: 'A higher sheath percentage implies...',
    answers: [
      'The sheath is tightly woven.',
      'The rope is more abrasion resistant.',
      'The rope is manufactured by Imlay Canyon Gear.',
    ],
    randomizeAnswers: true,
    allOfTheAbove: true,
    noneOfTheAbove: true,
    correctAnswer: 1,
    postAnswerRemarks: (
      <p>
        Higher <G>sheath</G> percentage is generally advertised as being more
        abrasion resistant. It also would mean that it has a lower breaking
        strength compared to an identical rope with a higher <G>core</G>{' '}
        percentage.
      </p>
    ),
  },
  {
    id: 'rope-019',
    text: 'What is the minimum acceptable breaking strength for canyoneering rope?',
    answers: ['4400 lbs when new', '5000 lbs', '22.2 kiloneutons'],
    randomizeAnswers: true,
    noneOfTheAbove: true,
    correctAnswer: 3,
    postAnswerRemarks: (
      <p>
        There is no standard except that if a rope breaks the manufacturer will
        be sued so they better get it right.
      </p>
    ),
  },
  {
    id: 'rope-020',
    text: 'The word "kern" means what in German?',
    answers: ['core', 'sheath', 'maize', 'mantle'],
    randomizeAnswers: true,
    noneOfTheAbove: true,
    correctAnswer: 0,
    postAnswerRemarks: <GlossaryQuote small>kernmantle rope</GlossaryQuote>,
  },
  {
    id: 'rope-021',
    text: 'The word "mantle" means what in German?',
    answers: ['core', 'sheath', 'vault', 'kern'],
    randomizeAnswers: true,
    noneOfTheAbove: true,
    correctAnswer: 1,
    postAnswerRemarks: <GlossaryQuote small>kernmantle rope</GlossaryQuote>,
  },
];
