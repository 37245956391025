// @flow
import React from 'react';
import type { RawQuestionType } from '../../../utils/quiz';
import * as crsd from '../../canyon-rating-system-definitions';
import { randomNum } from '../../../utils/random';

const getRandomRating = (ratingArray, exclude, include) => {
  if (!ratingArray) {
    ratingArray = crsd.ratingsArray;
  }

  if (include) {
    const hasInclude = ratingArray.reduce((acc, rating) => {
      if (acc) {
        return acc;
      }
      if (include === rating.rating) {
        return rating;
      }
      return acc;
    }, undefined);

    if (hasInclude) {
      return hasInclude;
    }
  }

  const length = ratingArray.length;
  let rating;
  do {
    const index = randomNum(length);
    rating = ratingArray[index];
  } while (exclude === rating.rating);

  return rating;
};

const getRandomCanyonRating = (exclude?: string, include?: string) => {
  return {
    technical: getRandomRating(crsd.technicalArray, exclude, include),
    water: getRandomRating(crsd.waterArray, exclude, include),
    time: getRandomRating(crsd.timeArray, exclude, include),
    risk: getRandomRating(crsd.riskArray, exclude, include),
    slot: getRandomRating(crsd.slotArray, exclude, include),
  };
};

const canyonRatingToKey = (canyonRating) => {
  let str = '';
  str += canyonRating.technical.rating;
  str += canyonRating.water.rating;
  str += canyonRating.time.rating !== 'NoTime' ? canyonRating.time.rating : '';
  str += canyonRating.risk.rating !== 'NoRisk' ? canyonRating.risk.rating : '';
  str += canyonRating.slot.rating !== 'NoSlot' ? canyonRating.slot.rating : '';
  return str;
};

const generateWhichCanyonRatingMatchesTheFollowDescription = (
  rating: string
): RawQuestionType => {
  const r = crsd.ratingsMap[rating];

  return {
    id: `canyon-rating-${r.rating}`,
    randomizeAnswers: true,
    text: (
      <>
        <p>Which canyon rating matches the following description?</p>
        <span>{r.description}</span>
      </>
    ),
    answers: [
      canyonRatingToKey(getRandomCanyonRating(undefined, r.rating)),
      canyonRatingToKey(getRandomCanyonRating(r.rating)),
      canyonRatingToKey(getRandomCanyonRating(r.rating)),
      canyonRatingToKey(getRandomCanyonRating(r.rating)),
    ],
    correctAnswer: 0,
    postAnswerRemarks: (
      <>
        {r.rating} - {r.ratingType} rating
      </>
    ),
  };
};

const generateWhichIsAnExampleOf = (ratingType: string): RawQuestionType => {
  // Get all the ratings that are ratingType
  const ratings = crsd.ratingsArray
    .filter((r) => r.ratingType === ratingType && r.description)
    .map((r) => r.rating);

  // Get all the ratings that aren't ratingType
  const otherRatings = crsd.ratingsArray
    .filter((r) => r.ratingType !== ratingType && r.description)
    .map((r) => r.rating);
  for (let i = 0; i < otherRatings.length; i++) {
    const j = randomNum(otherRatings.length);
    const tmp = otherRatings[i];
    otherRatings[i] = otherRatings[j];
    otherRatings[j] = tmp;
  }

  return {
    id: `canyon-rating-${ratingType}`,
    randomizeAnswers: true,
    text: (
      <>
        Which of these is an example of a <em>{ratingType}</em> rating?
      </>
    ),
    answers: [
      ratings[randomNum(ratings.length)],
      otherRatings[0],
      otherRatings[1],
      otherRatings[2],
    ],
    correctAnswer: 0,
    postAnswerRemarks: (
      <span>
        Valid <em>{ratingType}</em> ratings are{' '}
        {ratings.map((r) => (
          <React.Fragment key={r}>{r} </React.Fragment>
        ))}
      </span>
    ),
  };
};

const generate = (): Array<RawQuestionType> => {
  return [
    generateWhichCanyonRatingMatchesTheFollowDescription('1'),
    generateWhichCanyonRatingMatchesTheFollowDescription('2'),
    generateWhichCanyonRatingMatchesTheFollowDescription('3'),
    generateWhichCanyonRatingMatchesTheFollowDescription('4'),
    generateWhichCanyonRatingMatchesTheFollowDescription('A'),
    generateWhichCanyonRatingMatchesTheFollowDescription('B'),
    generateWhichCanyonRatingMatchesTheFollowDescription('C'),
    generateWhichCanyonRatingMatchesTheFollowDescription('I'),
    generateWhichCanyonRatingMatchesTheFollowDescription('II'),
    generateWhichCanyonRatingMatchesTheFollowDescription('III'),
    generateWhichCanyonRatingMatchesTheFollowDescription('IV'),
    generateWhichCanyonRatingMatchesTheFollowDescription('V'),
    generateWhichCanyonRatingMatchesTheFollowDescription('VI'),
    generateWhichCanyonRatingMatchesTheFollowDescription('R'),
    generateWhichCanyonRatingMatchesTheFollowDescription('X'),
    generateWhichCanyonRatingMatchesTheFollowDescription('S'),
    generateWhichIsAnExampleOf('technical'),
    generateWhichIsAnExampleOf('water'),
    generateWhichIsAnExampleOf('time'),
    generateWhichIsAnExampleOf('risk'),
    generateWhichIsAnExampleOf('slot'),
  ];
};

export const canyonRating = generate();
