// @flow
import React from 'react';
import Layout from '../components/layout';
import type { FrontMatter, ImageType } from '../utils/types';
import { withFrontMatter } from '../components';
import GlossaryQuote from '../components/glossary-quote';
import { graphql } from 'gatsby';
import Image from '../components/image';
import Link from '../components/link';
import G from '../components/glossary-link';
import route from '../routes';
import Quiz from '../components/quiz';
import { rope as quiz } from '../data/quiz';

type Props = {
  frontMatter: FrontMatter,
  data: {
    bluewaterCanyonator: ImageType,
    bluewaterCanyonExtreme: ImageType,
    imlayCanyonero: ImageType,
    imlayCanyonFire: ImageType,
    practiceRope: ImageType,
    ropeWear: ImageType,
    sterlingCanyonLux: ImageType,
    sterlingCanyonPrime: ImageType,
    sterlingCivGreen: ImageType,
    sterlingCivOrange: ImageType,
  },
};

class Canyoneering_Rope extends React.Component<Props> {
  render() {
    const { frontMatter, data } = this.props;

    return (
      <Layout frontMatter={frontMatter} toc>
        <h2>Your first rope</h2>
        <p>Get a 200' Imlay Canyon Fire. Don't buy a pull cord.</p>

        <h2>Definitions</h2>
        <GlossaryQuote>rope</GlossaryQuote>
        <GlossaryQuote>kernmantle rope</GlossaryQuote>
        <GlossaryQuote>core</GlossaryQuote>
        <GlossaryQuote>sheath</GlossaryQuote>
        <GlossaryQuote>static rope</GlossaryQuote>
        <GlossaryQuote>dynamic rope</GlossaryQuote>
        <GlossaryQuote>elongation</GlossaryQuote>
        <GlossaryQuote>skinny rope</GlossaryQuote>
        <GlossaryQuote>fat rope</GlossaryQuote>
        <GlossaryQuote>fast rope</GlossaryQuote>
        <GlossaryQuote>slow rope</GlossaryQuote>

        <h2>Length</h2>
        <p>
          Your first rope should be a 200'er. That length will get you into
          almost all the canyons.
        </p>
        <p>
          People say as you descend canyons you will <G>core shot</G> your rope
          so you can cut it and get smaller lengths eventually. For those of us
          who <em>don't</em> core shot all our ropes we can buy shorter fixed
          lengths or spool ends for much cheaper.
        </p>
        <p>
          The length of your second rope and beyond should be easier for you to
          decide. My second and third ropes were another 200' and a 120'.
        </p>
        <p>
          I have found that the closer the rope is to about 100', the more
          likely I will use it. I use my the short ropes all the time.
        </p>

        <h2>Rope Qualities that Matter</h2>
        <p>
          Length is important but is largely independent from the type of rope a
          canyoneer will use. The attributes you should care about are cost,
          durability, weight, and stretch. Let's talk about why each one
          matters.
        </p>
        <h3>Cost</h3>
        <p>
          Unless you're one of the 1% or own your own rope company, cost will
          probably matter. As a general rule, cost is determined by the rope
          material. And rope material (which I cover in a bit) determines weight
          and durability. So the more expensive it is, the lighter and/or more
          durable it will be.
        </p>
        <Image image={data.sterlingCanyonLux} />
        <Image
          image={data.bluewaterCanyonExtreme}
          caption="Sterling Canyon Lux and BlueWater Canyon Extreme - the two most costly canyoneering ropes"
        />
        <h3>Durability</h3>
        <p>
          I like to define durability as how bad can someone treat the rope
          before it is irreparably broken. The more durable it is the less you
          have to baby it and the more you can concentrate on other aspects of
          canyoneering, such as enjoying yourself.
        </p>
        <Image
          image={data.sterlingCivGreen}
          caption="Sterling C-IV - one of the most durable canyoneering ropes"
        />
        <h3>Weight</h3>
        <p>
          Heavier ropes means more work which often means a slower and more
          miserable ascents. Weight also means how heavy the rope is when wet,
          sandy, or muddy, which is almost never measured.
        </p>
        <Image
          image={data.imlayCanyonero}
          caption={<>Imlay Canyonero - the heaviest rope I own</>}
        />
        <Image
          image={data.bluewaterCanyonExtreme}
          caption={<>BlueWater Canyon Extreme - the lightest rope I own</>}
        />
        <h3>Stretch</h3>
        <p>
          In my opinion this is the least important of the <em>important</em>{' '}
          attributes. How much does the rope stretch when you rappel on it? Less
          stretch is better. This is absolutely not the same as elongation,
          which is one of the attributes I describe below.
        </p>
        <p>
          When you rappel on a stretchy rope here's what happens. The farther
          down you rappel the stretchier it gets. You'll realize that when you
          apply enough friction to stop the rope from going through your
          descender you will continue to go down a few more feet before the rope
          stretches enough to stop you. Kind of annoying but if you are careful
          it won't be a problem. Then you release the brake a little so you can
          start descending again. You'll start to go down again but at the same
          time the rope will unstretch and zip through your hand. This is a very
          scary sensation because you won't be in complete control. So you'll
          tightly grab the rope again and hope to stop. But you won't. The rope
          will now need to stretch out again before stopping you so you'll
          descend another 10 feet before stopping - hopefully you won't slip or
          anything. And this will continue until you make it to the bottom of
          the rappel. Super-duper annoying.
        </p>
        <Image
          image={data.sterlingCivGreen}
          caption={
            <>
              Sterling C-IV - one of the most stretchy canyoneering-specific
              ropes
            </>
          }
        />
        <Image
          image={data.imlayCanyonFire}
          caption={
            <>
              Imlay Canyon Fire - one of the least stretchy
              canyoneering-specific ropes
            </>
          }
        />

        <h2>Common Rope Attributes</h2>
        <p>
          This is a list of all the rope attributes I collect and how they
          affect each of the main four attributes defined above.
        </p>
        <h3>Diameter</h3>
        <p>
          The diameter of a rope affects weight and durability. All other things
          equal, the narrower a rope the lighter it is. However, it also means
          the rope is easier to cut and has less material that protects the
          core. Pretty straight forward. Skinny ropes often use more durable
          materials to make up for the lack of material.
        </p>
        <p>
          As a side note, narrower ropes also require more friction on the
          rappel device. This is not an important factor for choosing a rope so
          I won't cover it here but I will cover this more in detail whenever I
          write an article on rappel devices.
        </p>
        <Image
          image={data.imlayCanyonero}
          caption="Imlay Canyonero has a diameter of 9.2mm"
        />
        <Image
          image={data.sterlingCanyonLux}
          caption="Sterling Canyon Lux has a diameter of 8.0mm"
        />
        <h3>Sheath Material</h3>
        <p>
          Sheath material affects cost, durability, and weight. The materials
          used for the sheath are Nylon, Polyester, Technora, or a blend of
          Polyester and Technora. Nylon is inexpensive but weak, heavy, and
          holds a lot of water. Technora is expensive but durable and light.
          Polyester requires a bit of an explanation.
        </p>
        <Image
          image={data.sterlingCivOrange}
          caption="Technora sheath of a Sterling C-IV. Technora on canyoneering rope is always this tan color. The orange and black tracers are probably polyester."
        />
        <Image
          image={data.sterlingCanyonLux}
          caption="Blended Technora/polyester sheath. The Technora is tan and the rest is polyester"
        />
        <p>
          Some Polyester ropes have the sheath woven really tightly and densely.
          This provides some really good advantages and a couple of drawbacks
          when compared to Polyester ropes that aren't woven tightly.
        </p>
        <p>
          The advantages of a tightly woven Polyester sheath rope are that it
          makes the rope more hydrophobic and more durable (and I think more
          static but I'm not 100% sure on that). The disadvantages are that it
          is heavier and that over time the rope becomes very wiry.
        </p>
        <Image
          image={data.imlayCanyonFire}
          caption="Tightly woven polyester sheath on the Imlay Canyon Fire"
        />
        <p>
          The advantages of a loosely woven Polyester sheath rope is that it's
          "supple" as you expect a rope to be. The disadvantage is that it is
          extremely weak and will probably have to be retired around the same
          time as the densely woven rope starts to get wiry. When I hear someone
          describe a Polyester rope "supple" it's a red flag for me.
        </p>
        <p>
          One more disadvantage that's worth mentioning: <G>toggles</G> were
          designed to be used with wiry ropes. I have heard of instances where
          supple ropes bind too tight so that the toggles cannot be released
          which results in a <Link to={route.page_pull}>stuck rope</Link>.
        </p>
        <Image
          image={data.sterlingCanyonPrime}
          caption="Loosely woven sheath on the Sterling Canyon Prime. Can you tell the difference between the thread tightness on this and the above picture? I can't."
        />
        <p>
          Unfortunately there's no way to tell by looking at a product page
          which type of Polyester weave it is. I do know for a fact that all
          Imlay ropes and the BlueWater Canyonator are densely woven. I know the
          On Rope Canyoneering C.S.T. and Sterling Canyon Prime ropes are
          "supple".
        </p>
        <p>
          Just like diameter, sheath material also affects how much friction
          should be used when rappelling. Technora is generally more slippery
          than Nylon and Polyester so more friction should be used.
        </p>
        <h3>Core Material</h3>
        <p>
          Core material affects cost, weight, and stretch. The materials used
          for the core are Nylon, Polypropylene, Polyester, and Dyneema/Spectra.
          Nylon is inexpensive but uniquely terrible in every other attribute -
          heavy, stretchy, and loves to hold onto water like a sponge.
          Polypropylene is better since it's hydrophobic but it's still
          unacceptably stretchy. Polyester is inexpensive, heavy, static, and
          hydrophobic. Dyneema is expensive, light, static, and hydrophobic.
        </p>
        <h3>Weight</h3>
        <p>
          A slightly deceptive measurement. This is the weight of the rope when
          new. It doesn't account for how much water a rope holds when wet or
          how much dirt it will carry inside its fibers for eternity. However,
          aside from these problems it is actually one of the most useful pieces
          of information.
        </p>
        <h3>Elongation</h3>
        <p>
          Elongation should affect how much stretch a rope has. However, from a
          practical standpoint I consider this information worthless. Elongation
          is better thought of as a snapshot that only says, "This rope, when
          new, clean, and dry, weighted this much, will stretch this amount". So
          how do we parse this information? Let's take a look at two of my
          favorite ropes and compare their elongation.
        </p>
        <ul>
          <li>
            <Image image={data.imlayCanyonFire} />
            <b>Imlay Canyon Fire</b> has an elongation of 1.2% @ 390 lbs. This
            means that when new, clean, dry, and with 390 lbs of weight tied to
            it the Imlay Canyon Fire will stretch 1.2%. This rope is amazingly
            static when wet or dry, new or old, under all circumstances.
          </li>
          <li>
            <Image image={data.sterlingCivGreen} />
            <b>Sterling C-IV</b> has an elongation of 2% @ 300 lbs. This means
            that when new, clean, dry and with 300 lbs of weight tied to it the
            Sterling C-IV will stretch 2%. In actuality this rope is like
            rappelling on a rubber band. When I was describing the horrors of
            rappelling on a stretchy rope, this was the rope I was imagining. My
            first time on a C-IV I thought it was a dynamic climbing rope.
          </li>
        </ul>
        <p>
          I guess the information I'm trying to convey here is that, from a
          practical standpoint, these numbers don't provide any value to any of
          the 4 important rope attributes. The more reliable way to determine a
          rope's stretch is to look at its core material. Nylon and
          Polypropylene are stretchy and Polyester and Dyneema are not.
        </p>
        <h3>Tensile Strength</h3>
        <p>
          Tensile strength does not contribute to cost, durability, weight, or
          stretch. When one thinks of tensile strength they typically assume it
          means ultimate breaking strength. However, some manufacturers only
          publish <em>average</em> breaking strength.
        </p>
        <p>
          This number is extremely important to manufacturers because they must
          make their rope strong enough for canyoneering. For us canyoneers,
          though, it doesn't really matter because if a rope is manufactured as
          a canyoneering rope, this implies that the rope is sufficiently strong
          for all canyoneering activities.
        </p>
        <p>
          I couldn't imagine going canyoneering and refusing to use a
          canyoneering rope out of fear that it would not be strong enough to
          hold me.
        </p>
        <h3>Sheath Percentage</h3>
        <p>
          Sheath percentage affects durability. Sheath percentage is what
          percentage of the material that makes up the rope is part of the
          sheath, rather than the core. Typical kernmantle rope is about 50%.
          Few manufacturers provide this information and it is more of a
          marketing gimmick than anything.
        </p>

        <h2>Inspecting</h2>
        <p>
          The short answer is look for anything that looks or feels different
          than normal. Here's a list that came with a BlueWater rope I once got.
        </p>
        <ul>
          <li>
            <strong>Abrasion/Fraying</strong>
            <br />
            The core is exposed or more than half of the outer sheath yarns are
            abraded. Fraying indicates broken or damaged sheath bundles which is
            an indication of abrasion or overloading.
          </li>
          <li>
            <strong>Sheath Glazing</strong>
            <br />
            Glazing and/or glossy marks or hard, stiff areas signify heat
            damage. Typically this is the result of contact with a descender
            that has become overheated in a fast rappel.
          </li>
          <li>
            <strong>Uniformity of Diameter</strong>
            <br />A lack of uniformity in diameter or size indicates core
            damage. This is noted by a depression in the diameter of the rope,
            lumpiness of the rope or exposed core strands protruding from the
            rope.
          </li>
          <li>
            <strong>Discoloration</strong>
            <br />A change in the rope's original color is an indication of
            chemical damage or exposure to the elements of nature including
            Ultraviolet radiation.
          </li>
          <li>
            <strong>Flexibility</strong>
            <br />
            Inconsistency in texture of the rope can be an indication of
            excessive wear. This is most noted as soft or stiff areas of the
            rope.
          </li>
          <li>
            <strong>Exposed core fibers</strong>
            <br />
            Exposed core fibers indicate severe sheath damage and possible core
            damage.
          </li>
          <li>
            <strong>Age</strong>
            <br />
            Exposed core fibers indicate severe sheath damage and possible core
            damage.
          </li>
          <li>
            <strong>Loss of Faith</strong>
            <br />
            If you feel uncomfortable for any reason or suspect there may be a
            problem with the rope it must be retired and destroyed.
          </li>
        </ul>
        <p>
          Ideally after each person rappels you should inspect the rope. That's
          basically impossible, though, so maybe give it a quick inspection as
          you stuff it back into the bag and then a deeper inspection after each
          trip? I typically do a detailed inspection after I get home. I pull
          each rope out of its bag to let it dry. I then inspect it closely as I
          stuff it back into its bag for storage.
        </p>

        <h2>Rope wear log</h2>
        <Image
          image={data.ropeWear}
          caption={
            <>
              My rope wear log.{' '}
              <Link to="https://docs.google.com/spreadsheets/d/10vD9iD3dfOeeONTUT6PkkRzOPn2jxlBQbpw2t1RKLzU/edit?usp=sharing">
                See it
              </Link>{' '}
              or <Link to={route.page_about}>message me</Link> for your own
              copy.
            </>
          }
        />
        <p>I have an inspection log for that has the following information:</p>
        <ul>
          <li>
            <strong>Date</strong>
            <br />
            The date the rope was used.
          </li>
          <li>
            <strong>Rope</strong>
            <br />
            The rope that was used. For example "200' Canyonero" for my 200'
            Canyonero.
          </li>
          <li>
            <strong>Inspector</strong>
            <br />
            Person who does the rope inspection.
          </li>
          <li>
            <strong>Location</strong>
            <br />
            Either the canyon I descended or the area where we practiced.
          </li>
          <li>
            <strong>Temperature</strong>
            <br />
            One of "Cold", "Cool", "Warm", or "Hot".
          </li>
          <li>
            <strong>Weather</strong>
            <br />
            One of "Clear", "Scattered Clouds", "Partly Cloudy", "Overcast",
            "Rain", "Snow", or "NA". Yes, I have logged both "Rain" and "Snow".
            If it rains at all I use "Rain". Otherwise I just use whatever it
            was most of the time. I use "NA" for lead climbing at my local
            climbing gym.
          </li>
          <li>
            <strong>Time in sun</strong>
            <br />
            Amount of time it was exposed to sunlight.
          </li>
          <li>
            <strong>Number of rappels</strong>
            <br />
            Number of rappels we used this rope in the canyon.
          </li>
          <li>
            <strong>Number of canyoneers</strong>
            <br />
            Number of canyoneers who went on the trip.
          </li>
          <li>
            <strong>Number of falls</strong>
            <br />
            Number of times someone significantly loaded the rope. Not too
            applicable for rappelling but I do log whenever someone loses
            control and has to be caught.
          </li>
          <li>
            <strong>Severity of falls</strong>
            <br />A sentence or paragraph describing the fall(s) that took
            place.
          </li>
          <li>
            <strong>Inspection results</strong>
            <br />
            One column for each of the Inspection items listed above. The
            allowed values are "Pass" or "Fail".
          </li>
          <li>
            <strong>Notes</strong>
            <br />A sentence or paragraph with any pertinent notes. This is
            where I write about anything that failed inspection as well as
            non-critical rope-end problems such as sheath slippage or missing
            heat shrink tubes.
          </li>
        </ul>

        <h2>Rope Quiz</h2>
        <Quiz quiz={quiz} />
      </Layout>
    );
  }
}

export default withFrontMatter('rope')(Canyoneering_Rope);

export const query = graphql`
  query {
    bluewaterCanyonator: file(
      relativePath: { eq: "images/canyoneering/rope/bluewater-canyonator.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    bluewaterCanyonExtreme: file(
      relativePath: {
        eq: "images/canyoneering/rope/bluewater-canyon-extreme.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    imlayCanyonero: file(
      relativePath: { eq: "images/canyoneering/rope/imlay-canyonero.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    imlayCanyonFire: file(
      relativePath: { eq: "images/canyoneering/rope/imlay-canyon-fire.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    practiceRope: file(
      relativePath: { eq: "images/canyoneering/rope/practice-rope.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    ropeWear: file(
      relativePath: { eq: "images/canyoneering/rope/rope-wear.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sterlingCanyonLux: file(
      relativePath: { eq: "images/canyoneering/rope/sterling-canyon-lux.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    sterlingCanyonPrime: file(
      relativePath: { eq: "images/canyoneering/rope/sterling-canyon-prime.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    sterlingCivGreen: file(
      relativePath: { eq: "images/canyoneering/rope/sterling-civ-green.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    sterlingCivOrange: file(
      relativePath: { eq: "images/canyoneering/rope/sterling-civ-orange.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`;
